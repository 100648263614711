import useDisclosure from "hooks/useDisclosure";
import { noop } from "lodash";
import React, { createContext, useContext } from "react";

const PlanRestrictionContext = createContext([
  false,
  { toggle: noop, open: noop, close: noop },
]);

const PlanRestrictionContextProvider = (props) => {
  return <PlanRestrictionContext.Provider value={useDisclosure()} {...props} />;
};

export default PlanRestrictionContextProvider;

export const usePlanRestrictionContext = () =>
  useContext(PlanRestrictionContext);
