import axiosInstance from "api/axios.auth.config";
import { usePlanRestrictionContext } from "common/PlanRestrictionContext";
import { useEffect } from "react";

const usePlanRestrictionInterceptor = () => {
  const [open, { toggle }] = usePlanRestrictionContext();

  useEffect(() => {
    // Add the interceptor
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 402) {
          if (!open) {
            toggle();
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [toggle, open]);

  return null;
};

export default usePlanRestrictionInterceptor;
