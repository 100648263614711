/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useState } from "react";

const useDisclosure = (initial = false) => {
  const [opened, setOpen] = useState(() => initial ?? false);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return [opened, { open, close, toggle }];
};

export default useDisclosure;

const getSignalError = (signal, displays) => {
  const signaExist = Object.keys(displays).includes(signal);
  if (!signaExist) {
    throw new Error(
      `signal with name ${signal} does not exist please include the signal during intialization or remove it`
    );
  }
};
export const useMultiDisclosure = (displays = {}) => {
  const [opened, setOpen] = useState({});

  const open = useCallback((signal) => {
    getSignalError(signal, displays);
    setOpen((prev) => {
      const newVals = { ...prev, [signal]: true };
      return newVals;
    });
  }, []);

  const close = useCallback((signal) => {
    getSignalError(signal, displays);
    setOpen((prev) => {
      const newVals = { ...prev, [signal]: false };
      return newVals;
    });
  }, []);

  const toggle = useCallback((signal) => {
    getSignalError(signal, displays);
    setOpen((prev) => {
      const newVals = { ...prev, [signal]: !prev[signal] };
      return newVals;
    });
  }, []);

  return [(signal) => opened[signal], { signals: opened, open, close, toggle }];
};

export const useTextDisclosure = (display = "", options) => {
  const { withHistory = false } = options ?? {};
  const [history, setHistory] = useState([]);
  const [opened, setOpen] = useState(display);

  const handleOpen = (display, options) => {
    const { addToHistory = false } = options ?? {};
    setOpen(display);
    if (withHistory && addToHistory) {
      setHistory((prev) => [...prev, opened]);
    }
  };

  const handleClose = (options) => {
    const { stepBack = false } = options ?? {};
    if (stepBack) {
      const backTo = history?.[history.length - 1] ?? "";
      console.log(backTo, history);
      setOpen(backTo);
      setHistory((prev) => prev.slice(0, -1));
    } else {
      setOpen("");
    }
  };

  const toggle = useCallback((display) => {
    setOpen(display);
  }, []);

  const stepBack = useCallback(() => {
    setOpen(history?.[history.length - 1] ?? "");
    setHistory((prev) => prev.slice(0, -1));
  }, []);

  return [
    opened,
    {
      handleOpen,
      handleClose,
      toggle,
      stepBack,
      history,
      prev: history?.[history.length - 1] ?? "",
    },
  ];
};
