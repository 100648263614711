import React from "react";
import PlanRestrictionContextProvider from "./PlanRestrictionContext";
import PlanRestrictionModal from "./PlanRestrictionModal";
import usePlanRestrictionInterceptor from "hooks/usePlanRestrictionInterceptor";

const PlanRestriction = () => {
  return (
    <PlanRestrictionContextProvider>
      <RestrictionSetup />
    </PlanRestrictionContextProvider>
  );
};

export default PlanRestriction;

const RestrictionSetup = () => {
  usePlanRestrictionInterceptor();
  return <PlanRestrictionModal />;
};
